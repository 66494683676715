import "../../../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.12_@types+node@22.7.5_webpack@5.95.0/node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fapp%2F(accounts)%2F_components%2Fdesktop%2Fstyles.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA6VVbWvbMBD%2Bnl%2Bh0S%2FJwMHO0rz1y8ggW6CFUQr7GBTrbGuVJSPJednof9%2FJjuo4TZPCCCX0TnfPc3fPXfrG7gWYFQPzbFXxU6uEC%2FhGNVut4slwP6Yh%2BdshJKe7YMuZzWZkFIbF7s7ZuPS2wfS2tq1p%2FJxqVUoWxEooPSOlNGDJJ54XSlsqbfVKaQboC%2FsYRowSnJGbReQ%2Bd52XTv9dUj%2BAYqCnFlXUGDeFoPsZSQRUHKjgqQy4hdzMSAzSgnbm36WxPNkjL7RIe%2BxKaYH%2FUhF3w%2F74VkNOPpMN1d0gyJExlxAY9EKv58vOgKcZppj4VmB72rZLVTxxK8AXMThfhPsOGNcQW64kslOizKXzeJzIz6GgjHGZBmtlrcoPhUT9weVCGoJFwwz%2FVGlA%2FNK0KJpGf6k4ehwsBkd3Hvuk9g%2BknmVqA7oCiEttnGQKxevJNIlykOUSJ%2BrDhh8LQ3C0LHHkPvC2Dqm1uc1QJcfvDVAdZ0%2Bws0tZlNYHjVv1t%2Fp8VTCnZOYlRsvv2IziHpJXiEkFUS%2BG63Ag0BloyniJKp4ctqt21wQuvXAJtJPJ5Qxnnlyg%2B8AZa3Q7PSbsU4QtFH0QarPjSZJchHh0ER6BXkd4W2c0ul5o%2FeaNStzE7%2BkahCewPpw%2BneLK%2B6kfDl2CVyQw%2FA%2B4KzatNPBq3h42dByGJ8swpyyFFkZ8rMeb4WSxmE9d0NccGKfExBpAEioZ6R7d2%2FEIh9WrQn12Ljco5gfFqGhpd1S9ahGOhjiR9kVuzsog3GTO8vIOieaH4D9JjM6QOH%2FKrhxkZPoPvEa4occGAAA%3D%22%7D"
export var desktopProfileCard = 'styles_desktopProfileCard__c84y7a0';
export var desktopProfileCardHeader = 'styles_desktopProfileCardHeader__c84y7a1';
export var desktopProfileCardTitle = 'styles_desktopProfileCardTitle__c84y7a2';
export var filterButtonGroupLeft = 'styles_filterButtonGroupLeft__c84y7a8';
export var filterButtonGroupMiddle = 'styles_filterButtonGroupMiddle__c84y7a9';
export var filterButtonGroupRight = 'styles_filterButtonGroupRight__c84y7aa';
export var filterIcon = 'styles_filterIcon__c84y7a5';
export var filterInputLabel = 'styles_filterInputLabel__c84y7ab';
export var inviteModalInput = 'styles_inviteModalInput__c84y7a6';
export var menuItem = 'styles_menuItem__c84y7a4';
export var proBadgeLabel = 'styles_proBadgeLabel__c84y7ac';
export var profileCardCarouselWrapper = 'styles_profileCardCarouselWrapper__c84y7a3';
export var searchTextInput = 'styles_searchTextInput__c84y7a7';